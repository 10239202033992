import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .material-theme {
    background-color: #263238;
    color: #EEFFFF;
  }
  .material-theme .mtki { font-style: italic; }
  .material-theme .mtk3 { color: #89DDFF; }
  .material-theme .mtk9 { color: #F07178; }
  .material-theme .mtk10 { color: #C792EA; }
  .material-theme .mtk12 { color: #C3E88D; }
  .material-theme .mtk1 { color: #EEFFFF; }
  .material-theme .mtk6 { color: #F78C6C; }
  .material-theme .mtk13 { color: #B2CCD6; }
  .material-theme .mtk7 { color: #FFCB6B; }
  .material-theme .mtk8 { color: #82AAFF; }
  .material-theme .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>


    <p>{`The WordPress admin bar is outputting below HTML code to add margin-top to the html and body element of the page.`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "html",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`text/css`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`" `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`media`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`print`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`">`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`#`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`wpadminbar`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk13"
            }}>{`display`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` none`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`text/css`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`" `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`media`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`="`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`screen`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`">`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`html`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk13"
            }}>{`margin-top`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`32px`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`!important`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`*`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`html`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`body`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk13"
            }}>{`margin-top`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`32px`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`!important`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`@media`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` screen `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`and`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`max-width`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`782px`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`html`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk13"
            }}>{`margin-top`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`46px`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`!important`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`*`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`html`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`body`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk13"
            }}>{`margin-top`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`46px`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`!important`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span></span></span></code></pre>
    <p>{`However, if you like to have full control over what's happening and perhaps fixing this in your own stylesheet and thereby avoding unnecessary HTML, you can add this nifty line to your functions.php file (in your theme or plugin folder):`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "php",
      "data-index": "1"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk8"
            }}>{`add_theme_support`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`admin-bar`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`array`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`callback`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`__return_false`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`);`}</span></span></span></code></pre>
    <p>{`Previously you could have this code, but `}<strong parentName="p">{`it is no longer working:`}</strong></p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "php",
      "data-index": "2"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk8"
            }}>{`add_action`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`('`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`get_header`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`function`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`	`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`remove_action`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`('`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`wp2_head`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`_admin_bar_bump_cb`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`');`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`});`}</span></span></span></code></pre>
    <p>{`Hope it could be of help to you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      